 .StyledContactForm {
    display: flex;
    justify-content: center;


  form {
    display: flex;
    align-items: left;
    flex-direction: column;
    width: 35%;
    font-size: 16px;
    input {
      width: 100%;
      height: 35px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);
      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
    }
    textarea {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      max-height: 100px;
      min-height: 100px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);
      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
    }
    label {
      margin-top: 1rem;
    }
    input[type="submit"] {
      margin-top: 2rem;
      cursor: pointer;
      background:#97C00E;
      color: white;
      border: none;
    }
  }
 }
 @media screen and (max-width: 850px){

  .StyledContactForm{
    margin-top: 15%;
  }
 }