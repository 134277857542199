@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@800&family=Finger+Paint&family=Nova+Round&family=Roboto&display=swap');

$font-1: 'Nova Round', cursive;
$font-2: 'Dosis', sans-serif;
$font-3: 'Finger Paint' serif;

$color-1: #272643;
$color-2: #e3f6f5;
$color-3: #bae8e8;
$color-4: #2c698d;
$color-5: #81e9e6;
$white: #ddd;
$black: #333;

$borderRadius: 25px;
$shadow: 0 1px 3px #333;
$minHeight: 140vh;

@mixin verticalCenter {

  justify-content: center;
  align-items: center;
}

element.style {
  background-color: ghostwhite;
}

*,
::after,
::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  margin: auto;
  
  max-width: 100%;
  font-family: $font-1;
  color: $black;
}
button {
  cursor: pointer;
}
li {
  list-style-type: none;
  
}
a {
  text-decoration: none;
  color: $black;
  cursor: pointer;
  padding: 30px;
}
a:visited {
  color:$black;
}

  @media screen and (max-width: 850px){
    display: block !important;
  }

.homeContent, .offresContent, .pourquoiContent, .contactContent, {
  margin-left: .4rem;

  @media screen and (max-width: 850px){
    margin-left: 0 !important;
  }
}