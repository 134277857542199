.pourquoi {
  display: block;
}

.demarche, .creerEtDevelopper, .partenaires {
  
      margin: .5rem 0.5rem .5rem 0.5rem;
      border-radius: 25px;
      padding: .2rem;
     
}


.Parallax {
  display: flex;
  justify-content: center;

  
  &__background-triangles {
    width: 2000px;
    height: 1280px;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    
  }

  .list1 {
    padding-left: 1rem;
    list-style-type: none;
  }
  
  .list1 li {
    padding-left: 2rem;
    padding-top: 0.3%;
    background-image: url("../../media/leaf-outline.svg");
    background-position: 0 4px;
    background-size: 1rem 1rem;
    background-repeat: no-repeat;
  }
  


  &__background {
    background-image: url("../../media/Un.jpg");
    width: 250px;
    height: 350px;
    margin: 9.5rem 0.5rem .5rem 0.5rem;
    border-radius: 25px;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    z-index: 10;

  }

  &__background-triangles {
    
    background: #fff url("../../media/Quatre.jpg") center center/cover no-repeat content-box;
    box-shadow: 0 1px 3px #333;
      margin: .5rem 0.5rem .5rem 0.5rem;
      border-radius: 25px;
      padding: .2rem;
    
  }

  &__content {
    width: 1080px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    margin-top: 5%;

    &__heading,
    &__cta {
      text-align: justify;
      display: flex;
      flex-direction: column;
    }

    &__heading {
      
      width: 100%;
      padding: 55px;

      &__text {
        margin: 10px 0;
        font-size: 60px;
      }

      &__caption {
        margin: 0;
        font-size: 20px;
      }
    }

    &__cta {
      background-color: #ff737e;
      width: 60%;
      padding: 40px;

      font-size: 20px;
      color: #fff;

      margin-top: 120px;
    }

  }
}

@media screen and (max-width: 850px) {

.pourquoi{
  margin-top: 15%;
}

  .Parallax__background-triangles {
    width: 100%;
    height: 2500px;
  }
  .Parallax__content__heading, .Parallax__content__cta {
    text-align: inherit;
  }
}