.notreOffres {

  .notreOffresContent {
    width: 98%;
    margin-left: 2%;
    position: relative;
    overflow: hidden;
    display: grid;
    grid-template-columns: 65% 1fr;
    grid-template-rows: 96%;
    grid-template-areas: "T C";
  }

  .offresPres, .Carousselle {
    box-shadow: $shadow;
    margin: .5rem 0.5rem .5rem 0.5rem;
    border-radius: $borderRadius;
    padding: 1.2rem;
  }

  h3 {
    font-size: 1.4rem;
    text-align: center;
    margin-bottom: 1rem;
    margin-top: 2rem;
    font-family: $font-2;
    color: $color-1;
    text-transform: uppercase;
  }
  .AB {
    padding-left: 2rem;
    margin-left: 5px;
    background-image: url("../../media/AB.jpg");
    background-position: 0 4px;
    background-size: 1.5rem 1.5rem;
    background-repeat: no-repeat;
  }

  .TexteDePres{
    text-align: center;
  }

  p {
    margin: 0 0 10px 10px;
  }

  .offresPres {
    grid-area: C;
    margin-bottom: .5rem;
  }
  .homeCaroussel {
    box-sizing: border-box;
    grid-area: T;
  }

  .Carousselle {
    grid-area: T;
    margin-bottom: .5rem;
    background: url('../../media/champ-de-ble.jpg') no-repeat center/cover;

    &:after {
      content: '';
      position: absolute;
      margin-left: 25px;
      height: 80%;
      width: inherit;

      z-index: -1;
    }

  }
}
@media screen and (max-width: 850px){
  .notreOffres {
    display: block;
  
    .notreOffresContent {
      width: 98%;
      margin-top: 15%;
      position: relative;
      overflow: hidden;
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: 100%;
      grid-template-areas:
        "C";

        .offresPres {
          grid-area: C;
          box-shadow: none;
          h3 {
            font-size: 1.4rem;
            
            font-family: $font-2;
            color: $color-1;
            text-transform: uppercase;
            position: relative;
            z-index: 100;
          }
        }
  
      
  
      p {
        margin-left: 16%;
        margin-right: 12%;
        position: relative;
        text-align: left;
        z-index: 100;
      }
  
      .homeCaroussel {
        grid-area: C;
        margin-bottom: .5rem;
        z-index: 2;
      }
    }
  }
}