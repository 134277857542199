.home {
  display: block;

  .homeContent {
    width: 98%;
    position: relative;
    overflow: hidden;
    display: grid;
    grid-template-columns: 28% 1fr;
    grid-template-rows: 94%;
    grid-template-areas:
      "T C C C";


    .HomeTitre {
      box-shadow: $shadow;
      margin: .5rem 0.5rem .5rem 0.5rem;
      border-radius: $borderRadius;
      padding: .2rem;
    }
    h3 {
      font-size: 1.4rem;
      text-align: center;
      margin-bottom: 1rem;
      font-family: $font-2;
      color: $color-1;
      text-transform: uppercase;
    }

    p {
      margin-left: 10px;
    }

    .HomeTitre {
      grid-area: T;
      margin-bottom: .5rem;
      
    }
    .Carousselle {
      grid-area: C;
      margin-bottom: .5rem;
      




    .content {

      top: 20%;
      left: 13%;
      font-family: $font-1;

      h1 {
        font-size: 5rem;
        font-family: $font-3;
        color: $color-5;
        text-transform: uppercase;
        text-shadow: 3px 6px 2px $black;
      }

      h2 {
        font-size: 2.5rem;
        padding: 1.5rem 0 7rem;
        color: $color-5;
        text-shadow: 3px 6px 2px $black;
        letter-spacing: 0px;
        -webkit-text-stroke: 0px $color-5;
        transition: all 0.3s ease-out;

        &:hover {
          letter-spacing: 10px;
        }
      }

      

      .pdf {
        a {
          font-size: 1.5rem;
          background: $color-3;
          padding: 1rem 2rem;
          border-radius: $borderRadius;
          box-shadow: 0 5px 1px rgb(15, 15, 15);
          transition: 0.2s ease;

          &:hover {
            background: $color-1;
            color: #81e9e6;
          }
        }
      }
    }
    }
  }
}
@media screen and (max-width: 850px){
  .home {
    display: block;
  
    .homeContent {
      width: 98%;
      margin-top: 15%;
      position: relative;
      overflow: hidden;
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: 100%;
      grid-template-areas:
        "C";

        .HomeTitre {
          grid-area: C;
        }
  
      h3 {
        font-size: 1.4rem;
        text-align: center;
        margin-bottom: 1rem;
        font-family: $font-2;
        color: $color-1;
        text-transform: uppercase;
        position: relative;
        z-index: 100;
      }
  
      p {
        margin-left: 16%;
        margin-right: 12%;
        position: relative;
        text-align: left;
        z-index: 100;
      }
  
      .slider-container {
        grid-area: C;
        margin-bottom: .5rem;
        z-index: 2;
      }
    }
  }
@media screen and (max-width:580px){
  .homeContent {
    &:after {
    background: url('../../media/champ-de-ble.jpg') no-repeat 60% 50%/cover !important;
    }
    h1 {
      font-size: 3rem !important;
    }
    h2 {
      font-size: 1.8rem !important;
    }
  }
}}