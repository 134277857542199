.sidebar {
  font-family: $font-1;
  height: 100%;
  max-height: 15vh;
  width: 98%;
  margin-left: 1%;
  border-radius: $borderRadius;
  position: relative;
  z-index: 100;
  background-color: white;
  min-height: 50px;
  margin-bottom: 1.2rem;
  justify-content: space-between;
  display: grid;
  grid-template-columns: 22% 1fr;
  grid-template-rows: 100%;
  grid-template-areas:
      "L N N N";


  .id {
    grid-area: L;
    text-align: center;
    user-select: none;
    position: relative;
    display: grid;
    grid-template-columns: 48% 1fr;
    grid-template-rows: 100%;
    grid-template-areas:
        "B A";
        

        

    .idContent2 {
      grid-area: A;
      margin: 2px 5px 5px 5%;
    
      display: flex;
      flex-direction: row-reverse;
      bottom: 0;
      left: 50%;
      transform: translateX(0%);
      padding-bottom: 15px;
      width: 100%;
      height: 120%;
      background: url('../../media/logoActorius1.png') no-repeat center/contain;

      img {
        height: 100%;
        width: 100%;

      }

      h3 {
        font-size: 1.3rem;
        margin-left: 15px;
        text-transform: uppercase;
        letter-spacing: .2rem;
        margin-top: 4.5rem;
        color: $color-1;
      }
    }

    }


    .idContent {
      grid-area: B;
      margin: 15px 5px 5px 5px;
      max-height: 70%;
      display: flex;
      flex-direction: row-reverse;
      bottom: 0;
      left: 50%;
      transform: translateX(0%);
      padding-bottom: 15px;
      width: 100%;
      height: 50%;
      background: url('../../media/eliquide-bio-1024x550-1.jpg') no-repeat left center/contain ;

      img {
        height: 45%;
        width: 45%;

      }

      h3 {
        font-size: 1.3rem;
        margin-left: 15px;
        text-transform: uppercase;
        letter-spacing: .2rem;
        margin-top: 4.5rem;
        color: $color-1;
      }
    }
  }

  


  .navigation {
    padding: 2rem 1rem;
    display: contents;


    ul {
      height: 100%;
      display: flex;
      justify-content: center;



    }
    li {
      padding: 1rem 0 1rem .4rem;
      font-size: 100%;
      position: relative;
      display: contents;



      &:hover i {
        transform: translate(3px, 3px);
      }

      a {
        color: $color-1;
      }
      .navActive {
        color: $color-4;
        background-color: #F2EEE8;
      }

      i {
        position: absolute;
        font-size: 1.5rem;
        transform: translateY(3px);
        transition: .2s ease;
      }
      span {
        margin-left: 2.7rem;
        margin-right: 1.7rem;
      }
  }
}
.socialNetwork {

  margin-right: 25px;
  ul {
    display: flex;
    width: 100%;
    padding: 0 14px;
    justify-content: center;

    li {
      font-size: 1.5rem;
      background: $color-4;
      color: $color-3;
      border-radius: 50%;
      padding: .5rem 0.8rem;
      transition: .3s;
      cursor: pointer;

      a:visited {
        color: $color-2;
      }
      &:nth-child(1){
        padding: .5rem .85rem
      }
      &:nth-child(4){
        padding: .5rem .75rem
      }
      &:hover {
        background: $color-1;
      }
    }
  }
  .signature {
    margin-top: 2rem;
    text-align: center;
    letter-spacing: 0.1rem;
    font-size: .7rem;

    p {
      color: $color-5;

      &:hover {
        color: $color-4;
      }
    }
  }
}
@media screen and (max-width: 850px){

  .sidebar {
    position: fixed;
    height: auto;
    top: 0;
    display: flex;
    min-height: 50px;
    margin-bottom: .2rem;
    width: 100%;
    justify-content: space-around;
    background-color: opacify($color: #fbf6f67b, $amount: 0);

    .id, .socialNetwork {
      display: none;
    }
    
    .navigation {
      width: 100%;
      padding: .1rem 1rem;

      ul {
        display: flex;
        justify-content: space-between;

        span {
          margin-left: 1.5rem !important;
        }
      }
    }
  }
}



@media screen and (max-width:580px){
  .sidebar {
    ul {
      justify-content: space-around !important;

      li {
        padding: 1rem 0 !important;
      }
      i {
        font-size: 1.4rem !important;
        transform: translateX(-8px) translateY(-10px) !important;
      }
      a {
        border-radius: 50%;
      }
      span {
        display: none;
      }
    }
  }
}